import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getLocaleDateFormat } from '@angular/common';
import { Observable } from 'rxjs';
import { BASE_URL } from '../base_url_config';

@Injectable({
  providedIn: 'root'
})
export class HrService {
  constructor(private http: HttpClient) { }
  getStaffBranch(id) {
    const url = BASE_URL + 'getStaffBranch/' + id;
    return this.http.get<any>(url);
  }
  getAllBranch(): Observable<any> {
    const url = BASE_URL + 'getAllBranch';
    return this.http.get<any>(url);
  }

  getAllHR(): Observable<any> {
    const url = BASE_URL + 'getAllHR';
    return this.http.get<any>(url);
  }

  getStafftoHR(id) {
    const url = BASE_URL + 'getStafftoHR/' + id;
    return this.http.get<any>(url);
  }

  //performance

  getLeaveProfile(id){
    const url = BASE_URL + 'getLeaveProfile/' + id;
    return this.http.get<any>(url);
  }

  getPerformance(): Observable<any> {
    const url = BASE_URL + "getPerformance";
    return this.http.get<any>(url);
  }

  getPerformanceByStaffID(staff_id): Observable<any> {
    const url = BASE_URL + "getPerformanceByStaffID/" + staff_id;
    return this.http.get<any>(url);
  }

  getPerformanceId(id): Observable<any> {
    const url = BASE_URL + "getPerformance/" + id;
    return this.http.get<any>(url);
  }

  addPerformance(data): Observable<any> {
    const url = BASE_URL + "addPerformance";
    return this.http.post<any>(url, data);
  }

  editPerformance(data, id): Observable<any> {
    const url = BASE_URL + "editPerformance/" + id;
    return this.http.post<any>(url, data);
  }

  getLeaveType() {
    const url = BASE_URL + 'getLeaveType';
    return this.http.get<any>(url);
  }

  getListLeave(branch_id, today, type) {
    const url = BASE_URL + 'getListLeave/' + branch_id + '/' + today + '/' + type;
    console.log(url)
    return this.http.get<any>(url);
  }

  getHistoryLeaveByStaffId(staff_id) {
    const url = BASE_URL + 'getHistoryLeaveByStaffId/' + staff_id;
    return this.http.get<any>(url);
  }

  getHistoryPerformanceByStaffID(staff_id) {
    const url = BASE_URL + 'getHistoryPerformanceByStaffID/' + staff_id;
    return this.http.get<any>(url);
  }

  getDataLeaveByID(id) {
    const url = BASE_URL + 'getDataLeaveByID/' + id;
    return this.http.get<any>(url);
  }

  updateStatusLeave(data): Observable<any> {
    const url = BASE_URL + 'updateStatusLeave';
    return this.http.post<any>(url, data);
  }

  addLeave(data): Observable<any> {
    const url = BASE_URL + 'addLeave';
    return this.http.post<any>(url, data);
  }

  ///// Report Performace
  reportAllPerformance() {
    const url = BASE_URL + 'reportAllPerformance';
    return this.http.get<any>(url);
  }

  reportAllLeaveWork() {
    const url = BASE_URL + 'reportAllLeaveWork';
    return this.http.get<any>(url);
  }

  ///// get staff code

  getStaffCode(code): Observable<any> {
    const url = BASE_URL + "getStaffCode/" + code;
    return this.http.get<any>(url);
  }

  getAttendanceByStaffCode(code): Observable<any> {
    const url = BASE_URL + "getAttendanceByStaffCode/" + code;
    return this.http.get<any>(url);
  }

  newCheckin(qr_code, staff_code): Observable<any> {

    const url = BASE_URL + "newCheckin/" + qr_code + "/" + staff_code;
    return this.http.get<any>(url);
  }

  getAttendance(branch_id) {
    const url = BASE_URL + "getAttendance/" + branch_id;
    return this.http.get<any>(url);
  }

  getAttendanceCheckOut(branch_id) {
    const url = BASE_URL + "getAttendanceCheckOut/" + branch_id;
    return this.http.get<any>(url);
  }
  getAttendanceAdmin() {
    const url = BASE_URL + "getAttendanceAdmin";
    return this.http.get<any>(url);
  }

  getAttendanceCheckOutAdmin() {
    const url = BASE_URL + "getAttendanceCheckOutAdmin";
    return this.http.get<any>(url);
  }

  getAttendanceByDate(data) {
    const url = BASE_URL + 'getAttendanceByDate';
    return this.http.post<any>(url, data);
  }

  getAttendanceById(id) {
    const url = BASE_URL + "getAttendanceById/" + id;
    return this.http.get<any>(url);
  }

  deleteAttendance(data) {
    const url = BASE_URL + 'deleteAttendance';
    return this.http.post<any>(url, data);
  }

  updateAttendance(data) {
    const url = BASE_URL + 'updateAttendance';
    return this.http.post<any>(url, data);
  }

  searchStaffByCode(staff_code) {
    const url = BASE_URL + "searchStaffByCode/" + staff_code;
    return this.http.get<any>(url);
  }

  addAttendance(data) {
    const url = BASE_URL + 'addAttendance';
    return this.http.post<any>(url, data);
  }

  //////////////////// Benefit /////////////////////////

  getBenefit() {
    const url = BASE_URL + "getBenefit";
    return this.http.get<any>(url);
  }

  getBenefitThisMonth() {
    const url = BASE_URL + "getBenefitThisMonth";
    return this.http.get<any>(url);
  }

  searchBenefit(year_month) {
    const url = BASE_URL + "searchBenefit/" + year_month;
    return this.http.get<any>(url);
  }

  addBenefitForThisMonth(year_month) {
    const url = BASE_URL + "addBenefitForThisMonth/" + year_month;
    return this.http.get<any>(url);
  }

  updateBenefitById(data): Observable<any> {
    const url = BASE_URL + 'updateBenefitById';
    return this.http.post<any>(url, data);
  }

  deleteBenefitById(data): Observable<any> {
    const url = BASE_URL + 'deleteBenefitById';
    return this.http.post<any>(url, data);
  }

  //////////////// Config Leave Staff //////////////////////

  getConfigleaveStaff() {
    const url = BASE_URL + "getConfigleaveStaff";
    return this.http.get<any>(url);
  }

  getReportLeaveStaff(month) : Observable<any>{
    const url = BASE_URL + "newLeaveReport/" + month;
    return this.http.get<any>(url);
  }

  addConfigleaveStaff() {
    const url = BASE_URL + "addConfigleaveStaff";
    return this.http.get<any>(url);
  }

  updateConfigleaveStaff(data): Observable<any> {
    const url = BASE_URL + 'updateConfigleaveStaff';
    return this.http.post<any>(url, data);
  }


  // getLeaveProfile(id): Observable<any> {
  //   const url = BASE_URL + 'getLeaveProfile/' + id;
  //   return this.http.get<any>(url);
  // }
  /////getLeaveProfile/////////////////////////////////////////////////////

  //attrndance config
  getAttendanceConfig(): Observable<any> {
    const url = BASE_URL + "getAttendanceConfig";
    return this.http.get<any>(url);
  }

  checkAddTimeAttendance(): Observable<any> {
    const url = BASE_URL + "checkAddTimeAttendance";
    return this.http.get<any>(url);
  }

  editAttendanceConfig(data): Observable<any> {
    const url = BASE_URL + "updateAttendanceConfig";
    return this.http.post<any>(url,data);
  }

  //holiday
  getHoliday(month): Observable<any> {
    const url = BASE_URL + "getHoliday/" + month;
    return this.http.get<any>(url);
  }

  getHolidayId(id): Observable<any> {
    const url = BASE_URL + "getHolidayId/" + id;
    return this.http.get<any>(url);
  }

  addHoliday(data): Observable<any> {
    const url = BASE_URL + "addHoliday";
    return this.http.post<any>(url,data);
  }

  editHoliday(data): Observable<any> {
    const url = BASE_URL + "editHoliday";
    return this.http.post<any>(url,data);
  }

  deleteHoliday(data): Observable<any> {
    const url = BASE_URL + "deleteHoliday";
    return this.http.post<any>(url,data);
  }

}
