import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { getLocaleDateFormat } from '@angular/common';
import {  Observable } from 'rxjs';
import { BASE_URL } from '../base_url_config';
@Injectable({
  providedIn: 'root'
})
export class HelpdeskService {
  constructor(private http:HttpClient) {}

  getAllHelpDesk(): Observable<any> {
    const url= BASE_URL+'getAllHelpDesk';
    return this.http.get<any>(url);
  }

  getAllBranch(): Observable<any> {
    const url= BASE_URL+'getAllBranch';
    return this.http.get<any>(url);
  }

  addHelpDesk(data):Observable<any> {
    const url= BASE_URL+'newHelpDesk';
    return this.http.post<any>(url,data);
  }

  getHelpDeskId(id):Observable<any>{
    const url = BASE_URL + "getHelpDesk/" + id;
    return this.http.get<any>(url);
  }

  editHelpDesk(data, id):Observable<any>{
    const url = BASE_URL + "editHelpDesk/" + id;
    return this.http.post<any>(url, data);
  }

  //hekpdesk problem
  allHelpDeskProblem():Observable<any>{
    const url = BASE_URL + "getAllHelpDeskProblem";
    return this.http.get<any>(url)
  }

  getHelpDeskProblemId(id):Observable<any>{
    const url = BASE_URL + "getAllHelpDeskProblem/" + id;
    return this.http.get<any>(url)
  }

  addHelpDeskProblem(data): Observable<any>{
    const url = BASE_URL + "insertProblem";
    return this.http.post<any>(url, data);
  }

  editHelpDeskProblem(data, id): Observable<any>{
    const url = BASE_URL + "updateProblem/" + id;
    return this.http.post<any>(url, data);
  }

  deleteHelpDeskProblem(data): Observable<any>{
    const url = BASE_URL + "deleteProblem";
    return this.http.post<any>(url, data);
  }

  /// report
  helpDeskReport1(data): Observable<any>{
    const url = BASE_URL + "helpdeskByDate"
    return this.http.post<any>(url, data);
  }

  helpDeskReport2(data): Observable<any>{
    const url = BASE_URL+ "helpdeskCustomer"
    return this.http.post<any>(url, data);
  }

  helpDeskReport3(data): Observable<any>{
    const url = BASE_URL + "helpdeskBranch"
    return this.http.post<any>(url, data);
  }

  loadHelpDesk(): Observable<any>{
    const url = BASE_URL + "customerHelpdesk"
    return this.http.get<any>(url)
  }

  //all helpdesk 
  reportHelpdeskProblem(): Observable<any>{
    const url = BASE_URL + "countHelpdesk"
    return this.http.get<any>(url)
  }

  reportTenRankHelpdesk(): Observable<any>{
    const url = BASE_URL + "countHelpdeskRank"
    return this.http.get<any>(url)
  }
}
