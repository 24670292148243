
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../base_url_config';
@Injectable({
  providedIn: 'root'
})
export class BookkeepingService {

  constructor(private http: HttpClient) { }


  addCost(data): Observable<any> {
    const url = BASE_URL + 'addCost';
    return this.http.post<any>(url, data);
  }

  editCost(data): Observable<any> {
    const url = BASE_URL + 'editCost';
    return this.http.post<any>(url, data);
  }


  deleteCost(data): Observable<any> {
    const url = BASE_URL + 'deleteCost';
    return this.http.post<any>(url, data);
  }

  listCost(id, branch_id, startdate, enddate): Observable<any> {
    const url = BASE_URL + 'listCost/' + id + "/" + branch_id + "/" + startdate + "/" + enddate;
    return this.http.get<any>(url);
  }


  getCostId(id): Observable<any> {
    const url = BASE_URL + 'getCostId/' + id;
    return this.http.get<any>(url);
  }


  addDeposit(data): Observable<any> {
    const url = BASE_URL + 'addDeposit';
    return this.http.post<any>(url, data);
  }


  getListDeposit(branch_id, startDate, endDate): Observable<any> {
    const url = BASE_URL + 'getListDeposit' + '/' + branch_id + "/" + startDate + "/" + endDate;
    return this.http.get<any>(url);
  }


  documentDailyReport(branch_id, startdate, enddate): Observable<any> {
    const url = BASE_URL + 'documentDailyReport/' + branch_id + "/" + startdate + "/" + enddate;
    return this.http.get<any>(url);
  }




  historyDeposit(branch_id, startDate): Observable<any> {
    const url = BASE_URL + 'historyDeposit/' + branch_id + '/' + startDate;
    return this.http.get<any>(url);
  }


}


