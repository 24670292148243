import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL } from '../base_url_config';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  constructor(private http: HttpClient) { }

  getCrmDetail(crm_id, staff_id): Observable<any> {
    const url = BASE_URL + "getCrm/" + crm_id + "/" + staff_id
    return this.http.get<any>(url);
  }

  getCrmDataAdmin(startDate, endDate): Observable<any> {
    const url = BASE_URL + 'getCrmDataAdmin' + '/' + startDate + '/' + endDate;
    return this.http.get<any>(url);
  }

  getCrmDataBranch(id): Observable<any> {
    const url = BASE_URL + 'getCrmDataBranch/' + id;
    return this.http.get<any>(url);
  }

  getCrmDataSale(id, startDate, endDate): Observable<any> {
    const url = BASE_URL + 'getCrmDataSale/' + id + '/' + startDate + '/' + endDate;
    return this.http.get<any>(url);
  }

  getTag(): Observable<any> {
    const url = BASE_URL + "getTag";
    return this.http.get<any>(url);
  }

  getTagById(id): Observable<any> {
    const url = BASE_URL + "getTag/" + id;
    return this.http.get<any>(url);
  }

  getCustomerInTag(): Observable<any> {
    const url = BASE_URL + "customerInTag"
    return this.http.get<any>(url)
  }

  getCustomerTagById(id): Observable<any> {
    const url = BASE_URL + "tagCustomer/" + id
    return this.http.get<any>(url)
  }

  getTagOnCustomer(id): Observable<any> {
    const url = BASE_URL + "tagOnCustomer/" + id;
    return this.http.get<any>(url);
  }

  getCrmId(): Observable<any> {
    const url = BASE_URL + "maxId";
    return this.http.get<any>(url);
  }

  getCrmTripSale(staff_id, crm_id): Observable<any> {
    const url = BASE_URL + "crmTrip/" + staff_id + "/" + crm_id
    return this.http.get<any>(url);
  }

  getCrmCostSale(staff_id, crm_id): Observable<any> {
    const url = BASE_URL + "crmCost/" + staff_id + "/" + crm_id
    return this.http.get<any>(url)
  }

  getTripDetail(crm_id, trip_id): Observable<any> {
    const url = BASE_URL + "getTrip/" + crm_id + "/" + trip_id;
    return this.http.get<any>(url);
  }

  getCostDetail(crm_id, trip_id): Observable<any> {
    const url = BASE_URL + "crmCostDetail/" + crm_id + "/" + trip_id;
    return this.http.get<any>(url);
  }

  //////

  addCrm(data): Observable<any> {
    const url = BASE_URL + "newCrm";
    return this.http.post<any>(url, data);
  }

  addSaleTrip(data): Observable<any> {
    const url = BASE_URL + 'addTrip';
    return this.http.post<any>(url, data);
  }

  addSaleCost(data): Observable<any> {
    const url = BASE_URL + "addCost"
    return this.http.post<any>(url, data)
  }

  addCrmCost(data): Observable<any> {
    const url = BASE_URL + 'addCrmCost';
    return this.http.post<any>(url, data);
  }

  addCrmTrip(data): Observable<any> {
    const url = BASE_URL + 'addCrmTrip';
    return this.http.post<any>(url, data);
  }

  addTag(data): Observable<any> {
    const url = BASE_URL + "addTag";
    return this.http.post<any>(url, data);
  }
  //////////
  deleteSaleTrip(data): Observable<any> {
    const url = BASE_URL + 'deleteSaleTrip';
    return this.http.post<any>(url, data);
  }

  deleteCrmCost(data): Observable<any> {
    const url = BASE_URL + 'deleteCrmCost';
    return this.http.post<any>(url, data);
  }
  
  deleteCrmTrip(data):Observable<any> {
    const url= BASE_URL+'deleteTrip';
    return this.http.post<any>(url,data);
  }

  deleteTag(data, id): Observable<any> {
    const url = BASE_URL + "deleteTag/" + id;
    return this.http.post<any>(url, data);
  }

  /////
  editCrm(data): Observable<any> {
    const url = BASE_URL + "updateCrm"
    return this.http.post<any>(url, data)
  }

  editSaleTrip(data): Observable<any> {
    const url = BASE_URL + 'editSaleTrip';
    return this.http.post<any>(url, data);
  }

  editCrmCost(data): Observable<any> {
    const url = BASE_URL + 'editCrmCost';
    return this.http.post<any>(url, data);
  }

  editCrmTrip(data): Observable<any> {
    const url = BASE_URL + 'editCrmTrip';
    return this.http.post<any>(url, data);
  }

  editTag(data, id): Observable<any> {
    const url = BASE_URL + "editTag/" + id;
    return this.http.post<any>(url, data);
  }

  editTrip(data): Observable<any> {
    const url = BASE_URL + "updateTrip";
    return this.http.post<any>(url, data)
  }

  editCost(data): Observable<any> {
    const url = BASE_URL + "updateCrmCost"
    return this.http.post<any>(url, data);
  }


  listTrip(id): Observable<any> {
    const url = BASE_URL + 'listTrip/' + id;
    return this.http.get<any>(url);
  }

  getDashboardSaleList(staff_id, start_date, end_date): Observable<any> {
    const url = BASE_URL + 'getDashboardSaleList/' + staff_id + '/' + start_date + '/' + end_date;
    return this.http.get<any>(url);
  }

  getDashboardAdminList(start_date, end_date): Observable<any> {
    const url = BASE_URL + 'getDashboardAdminList/' + start_date + '/' + end_date;
    return this.http.get<any>(url);
  }
  ////////
  reportCRMforCost(data): Observable<any> {
    const url = BASE_URL + "reportCRMforCost"
    return this.http.post<any>(url, data);
  }

  reportCRMforCustomer(data): Observable<any> {
    const url = BASE_URL + "reportCRMforCustomer"
    return this.http.post<any>(url, data);
  }
}

